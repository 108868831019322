import React from 'react'

import {
  Blanket,
  BlanketTitle,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { OrganizationChart } from '../../components/OrganizationChart'
import { SEO } from '../../components/Seo'

export default () => (
  <Blanket>
    <SEO title={'Organigramma'} />

    <BlanketBackground>
      <Container>
        <BlanketTitle>Organigramma</BlanketTitle>

        <BlanketContent>
          <OrganizationChart />
        </BlanketContent>
      </Container>
    </BlanketBackground>
  </Blanket>
)
